/* eslint-disable max-len */
/* eslint new-cap: ["error", { "newIsCap": false }] */
/* eslint-disable camelcase */
import React, { useState } from 'react';
import Lodash from 'lodash';
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';
import { withStyles } from '@material-ui/core/styles';
import { Formik, Field } from 'formik';
import * as Yup from 'yup';
import { Container, Grid, Button } from '@material-ui/core';
import { PlnDropdown } from '../../Components/Input';
import style from './styles';

import {
  useProvincias,
  getCantonsByProvince,
} from '../../Core/Api/locationApi';

const FRMComiteEjecutivoCantonal = props => {
  const { classes } = props;
  const { data } = useProvincias();

  const doc = new jsPDF('p', 'px', 'letter');

  const [showPreview, setShowPreview] = useState(false);

  // Opciones Seleccionadas
  const [sProvincia, setSProvincia] = useState(9);
  // eslint-disable-next-line no-unused-vars
  const [sCanton, setSCanton] = useState(999);

  const [numDelegados] = useState(6);

  // Lista de: Provincias, Cantones, Distritos y Movimientos
  const [initialValues] = useState(
    Lodash.get(data, ['initValues'], {
      provincia: 9,
      canton: 999,
    }),
  );

  const provincias = Lodash.get(
    data,
    ['frmOptions'],
    [
      {
        itemLabel: '- SELECCIONE LA PROVINCIA -',
        value: 9,
      },
    ],
  );

  const [cantones, setCantones] = useState([
    { itemLabel: '- SELECCIONE EL CANTÓN-', value: 999 },
  ]);

  const validationSchema = Yup.object({});

  const setProvincia = p => {
    setShowPreview(false);
    setCantones([
      {
        itemLabel: '- SELECCIONE EL CANTÓN-',
        value: 999,
      },
    ]);
    initialValues.provincia = p;
    initialValues.canton = 999;
    initialValues.distrito = 999;
    if (p !== 9) {
      getCantonsByProvince(p).then(result => {
        if (result.status === 200) {
          const cantonsList = Lodash.get(result, [
            'data',
            'response',
          ]);

          const clist = cantonsList.map(
            ({ cod_canton, des_nombre_c }) => {
              return {
                itemLabel: des_nombre_c,
                value: cod_canton,
              };
            },
          );
          clist.unshift({
            itemLabel: '- SELECCIONE EL CANTÓN-',
            value: 999,
          });

          setSProvincia(
            Lodash.find(provincias, {
              value: p,
            }),
          );
          setCantones(clist);
        }
      });
    }
  };

  const setCanton = c => {
    setShowPreview(true);
    initialValues.provincia = sProvincia.value;
    initialValues.canton = c;

    if (c !== 999) {
      setSCanton(
        Lodash.find(cantones, {
          value: c,
        }),
      );
    }
  };

  const renderForm = () => {
    return (
      <div className={classes.boxForm}>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <h4>Provincia</h4>
            <Field
              name="provincia"
              data={provincias}
              component={PlnDropdown}
              onChange={provincia =>
                setProvincia(provincia)
              }
            />
          </Grid>
          <Grid item xs={6}>
            <h4>Cantón</h4>
            <Field
              name="canton"
              data={cantones}
              component={PlnDropdown}
              onChange={canton => setCanton(canton)}
            />
          </Grid>
        </Grid>
      </div>
    );
  };

  const tableHeaderTitle = [
    'PRIMER LUGAR',
    'SEGUNDO LUGAR',
    'TERCER LUGAR',
    'CUARTO LUGAR',
    'QUINTO LUGAR',
    'SEXTO LUGAR',
    'SÉPTIMO LUGAR',
    'OCTAVO LUGAR',
    'NOVENO LUGAR',
  ];

  const tableHeaderTitleCF = ['PROPIETARIO'];

  const getTimeStamp = () => {
    const now = new Date();
    return `${
      now.getMonth() + 1
    }/${now.getDate()}/${now.getFullYear()} ${now.getHours()}:${
      now.getMinutes() < 10
        ? `0${now.getMinutes()}`
        : now.getMinutes()
    }:${
      now.getSeconds() < 10
        ? `0${now.getSeconds()}`
        : now.getSeconds()
    }`;
  };

  const centeredText = (text, y) => {
    const textWidth =
      (doc.getStringUnitWidth(text) *
        doc.internal.getFontSize()) /
      doc.internal.scaleFactor;
    const textOffset =
      (doc.internal.pageSize.width - textWidth) / 2;
    doc.text(textOffset, y, text);
  };

  const getNotesPDF = () => {
    let notesData = [];

    notesData = [
      [
        // eslint-disable-next-line max-len
        `* No se aceptarán inscripciones con documentación o número de candidatos incompletos.`,
      ],
      [
        // eslint-disable-next-line max-len
        `* El gestor(a) es ahora el representante de la papeleta contenida en este formulario ante el T.E.I., para todos los efectos de su inscripción y tramitación.`,
      ],
      [
        // eslint-disable-next-line max-len
        `* RECUERDE: Las nominas deben ordenarse con alternabilidad de género. Debe haber dentro de los candidatos(as) al menos un 20% personas entre 18 a 35 años de edad.`,
      ],
    ];

    return notesData;
  };

  const generatePDF = () => {
    // ENCABEZADO DEL DOCUMENTO
    doc.setFont('times', 'bold');
    doc.text(
      20,
      40,
      process.env.REACT_APP_PDF_PARTIDO_REPORTS,
    );
    doc.setFontSize(12);
    doc.text(20, 60, 'Asambleas Cantonales');
    doc.setFont('times', 'normal');
    doc.text(
      20,
      80,
      process.env.REACT_APP_PDF_PERIODO_REPORTS,
    );

    doc.setFont('times', 'bold');

    centeredText(
      process.env.REACT_APP_PDF_TITLE_FORMULARIO_REPORTS,
      100,
    );

    doc.setFont('times', 'normal');
    doc.text(20, 120, 'Señores');
    doc.text(
      20,
      130,
      process.env.REACT_APP_PDF_TITLE_TRIBUNAL_REPORTS,
    );

    let paragraph = `Nosotros los abajo firmantes, miembros activos del Partido, de conformidad con lo dispuesto en el Reglamento para las Asambleas Cantonales, solicitamos la inscripción de las siguientes candidaturas para participar en la escogencia del Comité Ejecutivo Cantonal para:`;
    let lines = doc.splitTextToSize(paragraph, 415);
    doc.text(20, 140, lines);

    const columns = [
      'Posible N° de Papeleta',
      'Provincia',
      'Cantón',
    ];

    const typeTable1 = 'grid';
    const showTheHeader = true;
    const baseHeightText = 220;
    const baseHeightTable = 85;

    const datos1 = [
      ['', sProvincia.itemLabel, sCanton.itemLabel],
    ];

    // TABLA FORMULARIO
    doc.autoTable({
      head: [columns],
      body: datos1,
      theme: typeTable1,
      startY: 170,
      styles: {
        overflow: 'linebreak',
        cellWidth: 'wrap',
        font: 'times',
      },
      columnStyles: {
        0: {
          halign: 'center',
          fillColor: [255, 255, 255],
        },
        1: {
          halign: 'center',
          fillColor: [255, 255, 255],
        },
        2: {
          halign: 'center',
          fillColor: [255, 255, 255],
        },
      },
      headStyles: {
        fillColor: [204, 204, 204],
        fontSize: 9,
        textColor: 0,
        halign: 'center',
      },
      showHead: showTheHeader,
      margin: { left: 20 },
    });

    // TABLA: CANDIDATOS DELEGADOS DISTRITALES
    doc.setFontSize(12);
    doc.setFont('times', 'normal');
    // eslint-disable-next-line max-len
    paragraph = `Declaramos incondicionalmente nuestra adhesión al Partido y a sus principios ideológicos y programáticos y aceptamos en su totalidad las normas ético­jurídicas que rigen el proceso, así como las decisiones, resoluciones y acuerdos del Tribunal de Elecciones Internas. Igualmente nos comprometemos a contribuir económicamente al mantenimiento del Partido, según lo dispuesto en el Articulo 14, inciso a) del Estatuto. La nómina de nuestra papeleta, integrada en orden alternativo de género, es la siguiente:`;

    lines = doc.splitTextToSize(paragraph, 425);
    doc.text(20, baseHeightText, lines);

    const dinamicFields = numDelegados;
    const theTitle =
      'CANDIDATOS(AS) PARA EL COMITÉ EJECUTIVO CANTONAL';

    doc.setFont('times', 'bold');
    centeredText(theTitle, 280);

    let posY = doc.lastAutoTable.finalY + baseHeightTable;

    for (let n = 0; n < dinamicFields; n++) {
      if (n !== 0) {
        posY = doc.lastAutoTable.finalY + 5;
      }

      const numRow = n + 1;
      const columnsT = ['A'];
      let dataT = [];
      dataT = [[`${numRow} - ${tableHeaderTitle[n]}`]];

      doc.autoTable({
        head: [columnsT],
        body: dataT,
        theme: 'grid',
        startY: posY,
        styles: { font: 'times', fontStyle: 'bold' },
        columnStyles: {
          0: {
            halign: 'center',
            fillColor: [255, 255, 255],
            textColor: 0,
          },
        },
        headStyles: {
          fillColor: [204, 204, 204],
          fontSize: 9,
          textColor: 0,
          halign: 'center',
        },
        showHead: 'never',
        margin: { left: 20 },
      });

      const columns2 = [
        'CÉDULA',
        'PRIMER APELLIDO',
        'SEGUNDO APELLIDO',
        'NOMBRE',
      ];

      const datos2 = [['', '', '', '', '']];

      doc.autoTable({
        head: [columns2],
        body: datos2,
        theme: 'grid',
        startY: doc.lastAutoTable.finalY,
        styles: {
          overflow: 'linebreak',
          cellWidth: 'wrap',
          font: 'times',
        },
        columnStyles: {
          0: {
            halign: 'center',
            fillColor: [255, 255, 255],
          },
          1: {
            halign: 'center',
            fillColor: [255, 255, 255],
          },
          2: {
            halign: 'center',
            fillColor: [255, 255, 255],
          },
          3: {
            halign: 'center',
            fillColor: [255, 255, 255],
          },
        },
        headStyles: {
          fillColor: [204, 204, 204],
          fontSize: 9,
          textColor: 0,
          halign: 'center',
        },
        margin: { left: 20 },
      });

      const columns3 = ['A'];
      const data3 = [
        [
          // eslint-disable-next-line max-len
          `En este acto con mi firma de puño y letra, ratifico mi aceptación como candidato(a). Asimismo autorizo al gestor(a) para que deposite lo correspondiente a mi cuota de membrecía.`,
        ],
      ];

      // posY += 45;
      doc.autoTable({
        head: [columns3],
        body: data3,
        theme: 'grid',
        startY: doc.lastAutoTable.finalY,
        styles: { font: 'times' },
        columnStyles: {
          0: {
            halign: 'left',
            fillColor: [255, 255, 255],
            textColor: 0,
          },
        },
        headStyles: {
          fillColor: [204, 204, 204],
          fontSize: 9,
          textColor: 0,
          halign: 'center',
        },
        showHead: 'never',
        margin: { left: 20 },
      });

      const columns4 = ['', ''];
      const data4 = [
        [
          '                        FIRMA                            ',
          '_____________________________________________',
        ],
      ];

      doc.autoTable({
        head: [columns4],
        body: data4,
        theme: 'grid',
        startY: doc.lastAutoTable.finalY,
        styles: { font: 'times', color: '#000' },
        columnStyles: {
          0: {
            halign: 'center',
            fillColor: [255, 255, 255],
            textColor: 0,
          },
          1: {
            halign: 'center',
            fillColor: [255, 255, 255],
            textColor: 0,
          },
        },
        headStyles: {
          fillColor: [204, 204, 204],
          fontSize: 9,
          textColor: 0,
          halign: 'center',
        },
        showHead: 'never',
        margin: { left: 20 },
      });
    }

    // TABLA: CANDIDATOS FISCALÍA
    const columnsT2 = ['A'];
    const dataT2 = [[`CANDIDATO(A) A LA FISCALÍA`]];

    doc.autoTable({
      head: [columnsT2],
      body: dataT2,
      theme: 'plain',
      startY: doc.lastAutoTable.finalY + 10,
      styles: {
        font: 'times',
        fontStyle: 'bold',
      },
      columnStyles: {
        0: {
          halign: 'center',
          fillColor: [255, 255, 255],
          textColor: 0,
          fontSize: 12,
        },
      },
      headStyles: {
        fillColor: [204, 204, 204],
        fontSize: 9,
        textColor: 0,
        halign: 'center',
      },
      showHead: 'never',
      margin: { left: 20 },
    });

    posY = doc.lastAutoTable.finalY + 5;

    for (let n3 = 0; n3 < tableHeaderTitleCF.length; n3++) {
      if (n3 !== 0) {
        posY = doc.lastAutoTable.finalY + 5;
      }

      const columns2 = [
        'CÉDULA',
        'PRIMER APELLIDO',
        'SEGUNDO APELLIDO',
        'NOMBRE',
      ];
      const datos2 = [['', '', '', '', '']];

      // posY += 15;
      doc.autoTable({
        head: [columns2],
        body: datos2,
        theme: 'grid',
        startY: doc.lastAutoTable.finalY,
        styles: {
          overflow: 'linebreak',
          cellWidth: 'wrap',
          font: 'times',
        },
        columnStyles: {
          0: {
            halign: 'center',
            fillColor: [255, 255, 255],
          },
          1: {
            halign: 'center',
            fillColor: [255, 255, 255],
          },
          2: {
            halign: 'center',
            fillColor: [255, 255, 255],
          },
          3: {
            halign: 'center',
            fillColor: [255, 255, 255],
          },
        },
        headStyles: {
          fillColor: [204, 204, 204],
          fontSize: 9,
          textColor: 0,
          halign: 'center',
        },
        margin: { left: 20 },
      });

      const columns3 = ['A'];
      const data3 = [
        [
          // eslint-disable-next-line max-len
          `En este acto con mi firma de puño y letra, ratifico mi aceptación como candidato(a). Asimismo autorizo al gestor(a) para que deposite lo correspondiente a mi cuota de membrecía.`,
        ],
      ];

      // posY += 45;
      doc.autoTable({
        head: [columns3],
        body: data3,
        theme: 'grid',
        startY: doc.lastAutoTable.finalY,
        styles: { font: 'times' },
        columnStyles: {
          0: {
            halign: 'left',
            fillColor: [255, 255, 255],
            textColor: 0,
          },
        },
        headStyles: {
          fillColor: [204, 204, 204],
          fontSize: 9,
          textColor: 0,
          halign: 'center',
        },
        showHead: 'never',
        margin: { left: 20 },
      });

      const columns4 = ['', ''];
      const data4 = [
        [
          '                        FIRMA                            ',
          '_____________________________________________',
        ],
      ];

      // posY += 70;
      doc.autoTable({
        head: [columns4],
        body: data4,
        theme: 'grid',
        startY: doc.lastAutoTable.finalY,
        styles: { font: 'times', color: '#000' },
        columnStyles: {
          0: {
            halign: 'center',
            fillColor: [255, 255, 255],
            textColor: 0,
          },
          1: {
            halign: 'center',
            fillColor: [255, 255, 255],
            textColor: 0,
          },
        },
        headStyles: {
          fillColor: [204, 204, 204],
          fontSize: 9,
          textColor: 0,
          halign: 'center',
        },
        showHead: 'never',
        margin: { left: 20 },
      });
    }

    // TABLA: GESTOR DE LA PAPELETA
    const columnsT3 = ['A'];
    const dataT3 = [
      [
        `GESTOR DE LA PAPELETA ANTE EL TRIBUNAL DE ELECCIONES INTERNAS`,
      ],
    ];

    doc.autoTable({
      head: [columnsT3],
      body: dataT3,
      theme: 'plain',
      startY: doc.lastAutoTable.finalY + 10,
      styles: {
        font: 'times',
        fontStyle: 'bold',
      },
      columnStyles: {
        0: {
          halign: 'center',
          fillColor: [255, 255, 255],
          textColor: 0,
          fontSize: 12,
        },
      },
      headStyles: {
        fillColor: [204, 204, 204],
        fontSize: 9,
        textColor: 0,
        halign: 'center',
      },
      showHead: 'never',
      margin: { left: 20 },
    });

    posY = doc.lastAutoTable.finalY + 5;

    const columnsT4 = ['A', 'B'];
    const dataT4 = [
      [
        'CÉDULA',
        '                                             ',
      ],
      [
        'NOMBRE COMPLETO',
        '                                             ',
      ],
      [
        'FIRMA',
        '                                             ',
      ],
    ];

    doc.autoTable({
      head: [columnsT4],
      body: dataT4,
      theme: 'grid',
      startY: posY,
      styles: { font: 'times', fontStyle: 'bold' },
      columnStyles: {
        0: {
          halign: 'center',
          fillColor: [255, 255, 255],
          textColor: 0,
        },
        1: {
          halign: 'center',
          fillColor: [255, 255, 255],
          textColor: 0,
        },
      },
      headStyles: {
        fillColor: [204, 204, 204],
        fontSize: 9,
        textColor: 0,
        halign: 'center',
      },
      showHead: 'never',
      margin: { left: 20 },
    });

    posY = doc.lastAutoTable.finalY + 5;

    // TABLA: NOTAS
    const columnsT5 = ['A'];
    const dataT5 = [[`Notas`]];

    doc.autoTable({
      head: [columnsT5],
      body: dataT5,
      theme: 'grid',
      startY: doc.lastAutoTable.finalY + 10,
      styles: {
        font: 'times',
        fontStyle: 'bold',
      },
      columnStyles: {
        0: {
          halign: 'center',
          fillColor: [255, 255, 255],
          textColor: 0,
          fontSize: 12,
        },
      },
      headStyles: {
        fillColor: [204, 204, 204],
        fontSize: 9,
        textColor: 0,
        halign: 'center',
      },
      showHead: 'never',
      margin: { left: 20 },
    });

    const columnsT6 = ['A'];
    const dataT6 = getNotesPDF();

    doc.autoTable({
      head: [columnsT6],
      body: dataT6,
      theme: 'grid',
      startY: doc.lastAutoTable.finalY,
      styles: {
        font: 'times',
      },
      columnStyles: {
        0: {
          halign: 'left',
          fillColor: [255, 255, 255],
          textColor: 0,
          fontSize: 10,
        },
      },
      headStyles: {
        fillColor: [204, 204, 204],
        fontSize: 9,
        textColor: 0,
        halign: 'left',
      },
      showHead: 'never',
      margin: { left: 20 },
    });

    // FOOTER DOCUMENT
    const columnsPie = ['A'];
    const dataPie = [
      [
        // eslint-disable-next-line max-len
        `PLNDigital.com ${process.env.REACT_APP_PDF_YEAR_REPORTS} Email: consultas@plndigital.com PLN; Mata Redonda, 125m O. del edificio del M.A.G., Casa Liberacionista "José Figueres Ferrer" Tel: (506)2549­5500. Aptdo:10051­1000 San José. Sitio web desarrollado por Corporación DSI S.A.`,
      ],
    ];
    doc.autoTable({
      head: [columnsPie],
      body: dataPie,
      theme: 'plain',
      startY: doc.lastAutoTable.finalY + 50,
      styles: {
        font: 'times',
      },
      columnStyles: {
        0: {
          halign: 'left',
          fillColor: [255, 255, 255],
          textColor: 0,
          fontSize: 10,
        },
      },
      headStyles: {
        fillColor: [204, 204, 204],
        fontSize: 9,
        textColor: 0,
        halign: 'left',
      },
      showHead: 'never',
      margin: { left: 20 },
    });

    const fileTS = getTimeStamp();
    doc.save(
      `formulario-comite-ejecutivo-cantonal-${fileTS}.pdf`,
    );
  };

  if (!Lodash.isEmpty(provincias)) {
    return (
      <Container
        maxWidth="lg"
        className={classes.container}
      >
        <h3>FORMULARIO COMITÉ EJECUTIVO CANTONAL</h3>
        <h4 className={classes.subtitle}>
          Seleccione la Información Requerida
        </h4>
        <div className={classes.boxForm}>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
          >
            {renderForm()}
          </Formik>

          {showPreview ? (
            <div className={classes.boxDocToPdf}>
              <Grid container spacing={3}>
                <Grid
                  item
                  xs={12}
                  style={{ textAlign: 'left' }}
                >
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={generatePDF}
                    style={{ marginTop: 10 }}
                  >
                    DESCARGAR FORMULARIO
                  </Button>
                </Grid>
              </Grid>
            </div>
          ) : (
            ''
          )}
        </div>
      </Container>
    );
  }
  return '';
};

FRMComiteEjecutivoCantonal.propTypes = {};

export default withStyles(style)(
  FRMComiteEjecutivoCantonal,
);
