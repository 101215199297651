import axios from 'axios';

axios.defaults.baseURL = process.env.REACT_APP_API_BASE_URL;

/* eslint-disable import/prefer-default-export */
export const getTotalPapeletas = cedula => {
  const url = [`/gestores/totalPapeletasAOCP/${cedula}`];

  return axios.get(url.join('&'));
};

export const findGestor = cedula => {
  const url = [`/gestores/findGestor/${cedula}/aocp`];

  return axios.get(url.join('&'));
};

export const saveGestor = params => {
  const {
    gestorData,
    userData,
    telefonosData,
    direccionesData,
    emailsData,
  } = params;

  const gestorInfo = {
    typeApp: 'aocp',
    gestor: gestorData,
    user: userData,
    telefonos: telefonosData,
    direcciones: direccionesData,
    emails: emailsData,
  };

  return axios.post('/gestores', gestorInfo);
};
