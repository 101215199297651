/* eslint-disable max-len */
import axios from 'axios';

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  responseType: 'json',
});

const getReporteResultados001 = (
  codProvincia,
  codCanton,
) => {
  return axiosInstance.get(
    `adjudicacionPuestos/getReporteResultadosEAOCP001/${codProvincia}/${codCanton}`,
  );
};

export default {
  getReporteResultados001,
};
