/* eslint-disable max-len */
import RegistroGestores from '../Containers/RegistroGestores/RegistroGestores';
import Login from '../Containers/Login/Login';
import ForgotPassword from '../Containers/Login/ForgotPassword';
import MainPage from '../Containers/MainPage/MainPage';
import Dashboard from '../Containers/Dashboard/Dashboard';

// Registro Papeletas
import CantonesNoRepresentados from '../Containers/CantonesNoRepresentados/CantonesNoRepresentados';
import AddCantonesNoRepresentados from '../Containers/CantonesNoRepresentados/AddCantonesNoRepresentados';
import EditCantonesNoRepresentados from '../Containers/CantonesNoRepresentados/EditCantonesNoRepresentados';

// Registro Depositos
import RegistroDepositos from '../Containers/RegistroDepositos/RegistroDepositos';
import AddDeposito from '../Containers/RegistroDepositos/AddDeposito';
import EditDeposito from '../Containers/RegistroDepositos/EditDeposito';

// Consulta Debitos
import ConsultaDebitos from '../Containers/ConsultaDebitos/ConsultaDebitos';

// Formularios
import FRMCantonesNoRepresentados from '../Containers/Formulario/FRMCantonesNoRepresentados';
import FRMComiteEjecutivoCantonal from '../Containers/Formulario/FRMComiteEjecutivoCantonal';
import FRMHojaInformacion from '../Containers/Formulario/FRMHojaInformacion';

// Consultas Públicas
import CPPapeletasCantonesNoRepresentados from '../Containers/ConsultasPublicas/CPPapeletasCantonesNoRepresentados';
import CPPapeletasComiteEjecutivoCantonal from '../Containers/ConsultasPublicas/CPPapeletasComiteEjecutivoCantonal';

// Adjudicación de Puestos
import APCantonesNoRepresentados from '../Containers/AdjudicacionPuestos/APCantonesNoRepresentados';

const routesList = [
  {
    auth: false,
    component: MainPage,
    exact: true,
    name: 'mainPage',
    path: '/',
  },
  {
    auth: false,
    component: Login,
    exact: true,
    name: 'login',
    path: '/login',
  },
  {
    auth: false,
    component: ForgotPassword,
    exact: true,
    name: 'forgotPwd',
    path: '/forgot-password',
  },
  {
    auth: true,
    component: Dashboard,
    exact: true,
    name: 'dashboard',
    path: '/dashboard',
  },
  {
    auth: true,
    component: CantonesNoRepresentados,
    exact: true,
    name: 'cantones-no-representados',
    path: '/cantones-no-representados',
  },
  {
    auth: true,
    component: AddCantonesNoRepresentados,
    exact: true,
    name: 'addCantonesNoRepresentados',
    path: '/cantones-no-representados/add',
  },
  {
    auth: true,
    component: EditCantonesNoRepresentados,
    exact: true,
    name: 'editCantonesNoRepresentados',
    path: '/cantones-no-representados/edit/:id',
  },
  {
    auth: false,
    component: RegistroGestores,
    exact: true,
    name: 'registro-gestores',
    path: '/registro-gestores',
  },
  {
    auth: true,
    component: RegistroDepositos,
    exact: true,
    name: 'registroDepositos',
    path: '/registro-depositos',
  },
  {
    auth: true,
    component: AddDeposito,
    exact: true,
    name: 'addDeposito',
    path: '/registro-depositos/add',
  },
  {
    auth: true,
    component: EditDeposito,
    exact: true,
    name: 'editDeposito',
    path: '/registro-depositos/edit/:id',
  },
  {
    auth: true,
    component: ConsultaDebitos,
    exact: true,
    name: 'consultaDebitos',
    path: '/consulta-debitos',
  },
  {
    auth: false,
    component: FRMCantonesNoRepresentados,
    exact: true,
    name: 'frmCantonesNoRepresentados',
    path: '/formulario/cantones-no-representados',
  },
  {
    auth: false,
    component: FRMComiteEjecutivoCantonal,
    exact: true,
    name: 'frmComiteEjecutivoCantonal',
    path: '/formulario/comite-ejecutivo-cantonal',
  },
  {
    auth: false,
    component: FRMHojaInformacion,
    exact: true,
    name: 'FRMHojaInformacion',
    path: '/formulario/hoja-informacion',
  },
  {
    auth: false,
    component: CPPapeletasCantonesNoRepresentados,
    exact: true,
    name: 'cpPapeletasCantonesNoRepresentados',
    path:
      '/consulta-publica/papeletas-cantones-no-representados',
  },
  {
    auth: false,
    component: CPPapeletasComiteEjecutivoCantonal,
    exact: true,
    name: 'cpPapeletasComiteEjecutivoCantonal',
    path: '/consulta-publica/comite-ejecutivo-cantonal',
  },
  {
    auth: false,
    component: APCantonesNoRepresentados,
    exact: true,
    name: 'apCantonesNoRepresentados',
    path: '/adjudicacion-puestos/cantones-no-representados',
  },
];

export default routesList;
